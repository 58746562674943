.react-player {
    position: unset;
}

.react-player video {
    position: absolute;
    object-fit: cover !important;
}

.react-player .w-video-wrapper {
    background-color: transparent !important;
}

.react-player .wistia_embed {
    background-color: white !important;
}

.react-player img {
    border-width: 0 !important;
    border-radius: 1rem !important;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
}

@media screen and (min-width: 768px) {
    .react-player {
        position: absolute;
    }
}
